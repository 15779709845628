import React from 'react'

const PhotoValue = ({ entry }) => {
  return (
    <div className='grid grid-cols-3 gap-x-1 mr-2'>
      <img
        alt={entry.value}
        src={`/mobile-record-images/${entry.id}`}
      />
    </div>
  )
}

export default PhotoValue

